import React from "react"
// import { graphql, Link } from "gatsby"
import Layout from "../components/new-design/Layout"
import { Hero, Cards } from "../components/template"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../components/new-design/Container"
import { FaChevronLeft } from "react-icons/fa"
export default function Page({ location, pageContext: { data, list } }) {
  const metaImage = data.headerImage
    ? {
        src: data.headerImage?.asset?.url,
        width: data.headerImage?.asset?.metadata?.dimensions?.width,
        height: data.headerImage?.asset?.metadata?.dimensions?.height,
      }
    : null
  // console.log(data)
  return (
    <Layout>
      <Seo
        template
        image={metaImage}
        title={`${list} of ${data.title || data.name}`}
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      {/* <code>{JSON.stringify(data)}</code> */}
      {/* <Hero property={data} className="bg-black bg-opacity-50 rounded-xl" /> */}

      <div className="text-center py-12 lg:py-20 space-y-4 bg-white border-t">
        <Container>
          <Link to="/guide" className="flex gap-2 items-center">
            <FaChevronLeft />
            <span>Back</span>
          </Link>
        </Container>
        <div className="max-w-5xl mx-auto">
          <h1>{data.title || data.name}</h1>
          <p className="text-center pt-4">
            We've created a curated guide to everything Ibiza has to offer.
          </p>
        </div>
        <Container>
          <StaticImage
            src="../../static/img/header_imgae@2x.png"
            alt={data.name}
            className="rounded-2xl h-[350px] w-full object-cover"
          />
        </Container>
      </div>

      <Cards
        featured
        className="py-12"
        link="/guide/listing/"
        list={data.listings}
      />

      {/* <Container>
<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
{data.listings.map()}
</div>

</Container> */}

      {/* <NorImage content={data.listings} /> */}
      {data.listing && <OnlyName content={data.listings} />}
    </Layout>
  )
}

const OnlyName = ({ content }) => {
  return (
    <div className="py-6">
      <Container>
        <ul className="grid gap-4 md:grid-cols-3 xl:grid-cols-5 py-6 bg-white px-4 rounded-xl">
          {content.map((cata, key) => {
            return (
              !cata.featured && (
                <li key={key} className="w-full border p-3 rounded-xl">
                  <Link to={`/guide/listing/${cata.slug.current}/`}>
                    <h3 className="text-base">{cata.name}</h3>
                    <p className="text-xs text-stone-500">
                      {cata.shortDescription}
                    </p>
                  </Link>
                </li>
              )
            )
          })}
        </ul>
      </Container>
    </div>
  )
}
